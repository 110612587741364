<!-- View: Event History -->
<template>
  <v-container class="event-page--history">

    <!-- pageHeader -->
    <v-row align="center" class="mb-4" dense>
      <v-col cols="12" md="8">
        <PageTitle>Eventos Anteriores</PageTitle>
      </v-col>
      <v-col cols="12" md="4">
        <v-row justify="end">
          <v-col cols="6">
            <v-select v-model="selectedMonth" :items="months" label="Mes" @change="changeDate" solo flat hide-details />
          </v-col>
          <v-col cols="6">
            <v-select v-model="selectedYear" :items="years" label="Año" @change="changeDate" solo flat hide-details />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-if="loading"> <v-skeleton-loader v-for="n in 10" :key="n" type="list-item-avatar-two-line" class="mb-3" />
    </div>

    <!-- eventlistitem -->
    <EventListItem v-for="event in events" :item="event" :key="event.docId" v-else />
  </v-container>
</template>

<script>
import moment from 'moment';
import { getEventsByMonth } from '@/api/historical';

import PageTitle from '@/components/layout/PageTitle.vue';
import EventListItem from '../../components/EventListItem.vue';

export default {
  components: { PageTitle, EventListItem },

  data: () => ({
    loading: false,
    events: [],
    selectedMonth: moment().month() + 1,
    selectedYear: moment().year(),
    years: [],
    months: [
      { text: 'Enero', value: 1 },
      { text: 'Febrero', value: 2 },
      { text: 'Marzo', value: 3 },
      { text: 'Abril', value: 4 },
      { text: 'Mayo', value: 5 },
      { text: 'Junio', value: 6 },
      { text: 'Julio', value: 7 },
      { text: 'Agosto', value: 8 },
      { text: 'Septiembre', value: 9 },
      { text: 'Octubre', value: 10 },
      { text: 'Noviembre', value: 11 },
      { text: 'Diciembre', value: 12 }
    ],
  }),

  methods: {
    async fetchEvents() {
      try {
        this.loading = true;
        this.events = await getEventsByMonth(this.selectedMonth, this.selectedYear);
      } catch (error) {
        console.debug(error);
      } finally {
        this.loading = false;
      }
    },

    async changeDate() {
      this.fetchEvents();
    },

    getYears() {
      const items = [];
      const current = moment().year();
      for (let i = current; i >= current - 10; i--) {
        items.push(i);
      }
      this.years = items;
    }
  },

  mounted() {
    this.getYears();
    this.fetchEvents();
  }
}
</script>

<style lang="css" scoped>
/* Style scoped view */
</style>