<script>
export default {
    name: 'EventListItem',
    props: ['item'],
}
</script>

<template>
    <v-card :to="{ name: 'MyEvent', params: { uid: item.docId } }" class="mb-3" flat>
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-subtitle class="text-body-2 primary--text font-weight-bold text-uppercase">
                    {{ item.date }}
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-icon>mdi-ticket-account</v-icon>
                <v-list-item-action-text class="font-weight-bold">{{ item.ticketCount }}</v-list-item-action-text>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<style lang="css" scoped></style>