import moment from 'moment';
import store from "@/store";
import { db } from "@/firebase";
import { dateToString } from "./utilities"
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

export const getEventsByMonth = async (month, year) => {
    try {
        // init
        const companyId = store.state.auth.user.companyId;
        const eventPath = `/companies/${companyId}/events`;
        console.debug(eventPath)

        // date
        const firstDay = moment(`${year}-${month}`, "YYYY-M").startOf("month");
        const lastDay = moment(`${year}-${month}`, "YYYY-M").endOf("month");

        // query
        const q = query(collection(db, eventPath), where("startDate", ">=", firstDay.toDate()), where("startDate", "<=", lastDay.toDate()), orderBy("startDate", "desc"));
        const querySnapshot = await getDocs(q);

        // process
        const items = []
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            items.push({ docId: doc.id, ...data, date: dateToString(data.startDate, "DD-MMM - hh:mm a") });
        });

        return items;
    } catch (error) {
        console.error("Error fetching events:", error);
        throw error;
    }
}